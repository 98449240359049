import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Practitioner } from '../../../../../types/FHIRTypes/Practitioner'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Address from '../../../DataTypes/Address'
import Identifier from '../../../DataTypes/Identifier'
import Code from '../../../DataTypes/Code'
import Boolean from '../../../DataTypes/Boolean'
import HumanName from '../../../DataTypes/HumanName'
import ContactPoint from '../../../DataTypes/ContactPoint'
import Date from '../../../DataTypes/Date'
import Narrative from '../../../DataTypes/Narrative'
import ResourceType from '../../../DisplayComponents/ResourceType'
import StringDisplay from '../../../DataTypes/String'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import URI from '../../../DataTypes/URI'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import PractitionerQualifications from '../../../DisplayComponents/PractitionerQualifications'

interface PractitionerDetailProps {
    resource: Practitioner | null
}

const config: FieldConfig = {
    identifier: { label: 'Practitioner Identifier', visibility: 'always' },
    active: { label: 'Active', visibility: 'always' },
    name: { label: 'Name', visibility: 'always' },
    telecom: { label: 'Contact Information', visibility: 'always' },
    address: { label: 'Address', visibility: 'always' },
    gender: { label: 'Gender', visibility: 'conditional' },
    birthDate: { label: 'Birth Date', visibility: 'conditional' },
    qualification: { label: 'Qualification', visibility: 'always' },
    communication: { label: 'Communication Language', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
}

const PractitionerDetail: React.FC<PractitionerDetailProps> = ({ resource }) => {
    if (!resource) {
        return (
            <div>
                No Data Found
            </div>
        )
    }

    return (
        <section>
            <h3>Practitioners</h3>
            <dl className='dataContainer'>
                <Row>
                    <ShowField field='identifier' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.identifier.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd><Identifier identifiers={resource.identifier} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='active' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.active.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd><Boolean data={resource.active} dataExtension={resource._active} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='name' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.name.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd>
                                {resource.name?.map((name, index) => (
                                    <HumanName key={index} name={name} />
                                ))}
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='telecom' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.telecom.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd>
                                {resource.telecom?.map((contact, index) => (
                                    <ContactPoint key={index} contactPoint={contact} />
                                ))}
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='address' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.address.label}</dt>
                        </Col>
                        <Col sm={8}>
                            {resource.address?.map((address, index) => (
                                <Address
                                    key={`address-${index}`}
                                    address={address}
                                    showUse={resource.address && resource.address.length > 1}
                                />
                            ))}
                        </Col>
                    </ShowField>
                    <ShowField field='gender' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.gender.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd><Code data={resource.gender} dataExtension={resource._gender} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='birthDate' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.birthDate.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd><Date data={resource.birthDate} dataExtension={resource._birthDate} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='qualification' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.qualification.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd><PractitionerQualifications data={resource.qualification} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='communication' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.communication.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd><CodeableConcept data={resource.communication} dataExtension={resource._communication} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='text' config={config} resource={resource}>
                        <Col sm={4}>
                            <dt>{config.text.label}</dt>
                        </Col>
                        <Col sm={8}>
                            <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                        </Col>
                    </ShowField>
                    <Col sm={12} className='footer'>
                        <hr />

                        <h6>FHIR Resource Metadata</h6>
                        <Row>
                            <ShowField field='resourceType' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.resourceType.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><ResourceType resourceType={resource.resourceType} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='id' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.id.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='meta' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.meta.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><MetaLastUpdated meta={resource.meta} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='profile' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.profile.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><Profile profile={resource.meta.profile} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='language' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.language.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='implicitRules' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.implicitRules.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                </Col>
                            </ShowField>
                        </Row>
                    </Col>
                </Row>
            </dl>
        </section>
    )
}

export default PractitionerDetail
import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { BsLink} from 'react-icons/bs'

interface ReferencePopoverProps {
    title: string;
    children: React.ReactNode;
}

const ReferencePopover: React.FC<ReferencePopoverProps> = ({ title, children }) => {
    return (
        <OverlayTrigger
            placement='right'
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Popover id="reference-popover" className='reference-popover'>
                    <Popover.Title as="h3" className='font-weight-bold'>{title}</Popover.Title>
                    <Popover.Content>
                        {children}
                    </Popover.Content>
                </Popover>
            }
        >
            <BsLink className='ml-2' />
        </OverlayTrigger>
    )
}

export default ReferencePopover
import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import { FieldConfig } from '../../../../../types/FieldConfig'
import Identifier from '../../../DataTypes/Identifier'
import Narrative from '../../../DataTypes/Narrative'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import LocationReference from '../../../DisplayComponents/LocationReference'
import DateTimeOrString from '../../../DataTypes/DateTimeOrString'
import { ImmunizationBundle } from '../../../../../types/FHIRTypes/Bundle'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Profile from '../../../DisplayComponents/Profile'
import URL from '../../../DataTypes/URL'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import Boolean from '../../../DataTypes/Boolean'
import SimpleQuantity from '../../../DataTypes/SimpleQuantity'
import URI from '../../../DataTypes/URI'

interface Props {
  detailId: string;
  immunizationData: ImmunizationBundle;
  patientData: any;
  isFetching: boolean;
}

const config: FieldConfig = {
  patient: { label: 'Patient', visibility: 'always' },
  status: { label: 'Status', visibility: 'always' },
  statusReason: { label: 'Reason Vaccine Not Performed', visibility: 'conditional' },
  vaccineCode: { label: 'Vaccine Code', visibility: 'always' },
  occurrence: { label: 'Date Administered', visibility: 'always' },
  encounter: { label: 'Encounter', visibility: 'conditional' },
  performer: { label: 'Performer', visibility: 'conditional' },
  primarySource: { label: 'Primary Source', visibility: 'always' },
  location: { label: 'Location', visibility: 'conditional' },
  route: { label: 'Route', visibility: 'conditional' },
  doseQuantity: { label: 'Dose Quantity', visibility: 'conditional' },
  text: { label: 'Summary', visibility: 'conditional' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const ImmunizationDetail: FC<Props> = ({ detailId, immunizationData, patientData, isFetching }: Props) => {
  const [resource, setResource] = useState<any>(null)
  const history = useHistory()

  useEffect(() => {
    if (!immunizationData) return

    const foundImmunization = immunizationData.entry?.find((immunization: any) => immunization.resource.id === detailId)
    if (foundImmunization) {
      setResource(foundImmunization.resource)
    } else {
      history.push('/404')
    }
  }, [immunizationData])

  return (
    <section>
      {
        isFetching ? (
          <tr>
            <td colSpan={6} rowSpan={15} className='d-flex'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              <span style={{ marginLeft: '10px' }}>Loading Member Immunizations...</span>
            </td>
          </tr>
        ) : resource ? (
          <>
            <dl className='dataContainer'>
              <Row>
                <ShowField field='patient' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.patient.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><PatientReference patient={resource.patient} /></dd>
                  </Col>
                </ShowField>

                <ShowField field='status' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.status.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                  </Col>
                </ShowField>

                <ShowField field='statusReason' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.statusReason.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><CodeableConcept data={resource.statusReason} dataExtension={resource._statusReason} /></dd>
                  </Col>
                </ShowField>

                <ShowField field='vaccineCode' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.vaccineCode.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><CodeableConcept data={resource.vaccineCode} dataExtension={resource._vaccineCode} /></dd>
                  </Col>
                </ShowField>

                <ShowField field='occurrence' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.occurrence.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>{<DateTimeOrString dateTime={resource?.occurrenceDateTime} string={resource?.occurrenceString} />}</dd>
                  </Col>
                </ShowField>

                <ShowField field='encounter' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.encounter.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <Reference data={resource.encounter} dataExtension={resource._encounter} />
                    </dd>
                  </Col>
                </ShowField>

                <ShowField field='performer' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.performer.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>{resource?.performer?.map((performer: any, index: any) => (
                      <tr key={`performer-${index}`}>
                        <td>{'Function: '}<CodeableConcept data={performer.function} dataExtension={performer._function} /></td>
                        <td>{'Actor: '}<Reference data={performer.actor} dataExtension={performer._actor} /></td>
                      </tr>
                    ))}
                    </dd>
                  </Col>
                </ShowField>

                <ShowField field='primarySource' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.primarySource.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <Boolean data={resource.primarySource} dataExtension={resource._primarySource} />
                    </dd>
                  </Col>
                </ShowField>

                <ShowField field='location' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.location.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><LocationReference location={resource.location} /></dd>
                  </Col>
                </ShowField>

                <ShowField field='route' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.route.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><CodeableConcept data={resource.route} dataExtension={resource._route} /></dd>
                  </Col>
                </ShowField>

                <ShowField field='doseQuantity' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.doseQuantity.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><SimpleQuantity data={resource.doseQuantity} dataExtension={resource._doseQuantity} /></dd>
                  </Col>
                </ShowField>

                <ShowField field='text' config={config} resource={resource}>
                  <Col sm={4}>
                    <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                  </Col>
                </ShowField>
                <Col sm={12} className='footer'>
                  <hr />
                  <h6>FHIR Resource Metadata</h6>
                  <Row>
                    <ShowField field='resourceType' config={config} resource={resource}>
                      <Col sm={4}>
                        <dt>{config.resourceType.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                      </Col >
                    </ShowField >

                    <ShowField field='id' config={config} resource={resource}>
                      <Col sm={4}>
                        <dt>{config.id.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='meta' config={config} resource={resource}>
                      <Col sm={4}>
                        <dt>{config.meta.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><MetaLastUpdated meta={resource.meta} /></dd>
                      </Col >
                    </ShowField >

                    <ShowField field='profile' config={config} resource={resource.meta}>
                      <Col sm={4}>
                        <dt>{config.profile.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>{<Profile profile={resource.meta.profile} />}</dd>
                      </Col >
                    </ShowField >

                    <ShowField field='implicitRules' config={config} resource={resource}>
                      <Col sm={4}>
                        <dt>{config.implicitRules.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                      </Col>
                    </ShowField>

                    <ShowField field='language' config={config} resource={resource}>
                      <Col sm={4}>
                        <dt>{config.language.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='identifier' config={config} resource={resource}>
                      <Col sm={4}>
                        <dt>{config.identifier.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>
                          <Identifier identifiers={resource.identifier} />
                        </dd>
                      </Col >
                    </ShowField >
                  </Row >
                </Col >

                <Col sm={12}>
                  <ProvenanceDetail resourceName='Immunization' resourceId={detailId} />
                </Col>
              </Row >
            </dl >
          </>
        ) :
          <tr>
            <td colSpan={6} rowSpan={15} className='text-center'>
              No Immunizations Details found for this member.
            </td>
          </tr>
      }
    </section >
  )
}

export default ImmunizationDetail
import React, { FC } from 'react'
import { CodeableConcept as FhirCodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Coding } from '../../../../types/FHIRTypes/Coding'
import DataAbsentReason from '../DataAbsentReason'
import Extension from '../Extension'

interface CodeableConceptProps {
  data?: FhirCodeableConcept | FhirCodeableConcept[]
  dataExtension?: FhirCodeableConcept | FhirCodeableConcept[] | null
}

const CodeableConcept: FC<CodeableConceptProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) return null

  if (!data && dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  const dataArray = Array.isArray(data) ? data : data ? [data] : []

  const renderContent = (item: FhirCodeableConcept) => {
    if (item.text) {
      return <span>{item.text}</span>
    }
    if (item.coding) {
      const codingValues = item.coding.map((coding: Coding) => coding.display || coding.code).filter(Boolean).join(', ')
      return <span>{codingValues}</span>
    }
    return <Extension nestedExtension={item} />
  }

  return (
    <div>
      {dataArray.map((item, index) => (
        <div key={index}>
          {renderContent(item)}
        </div>
      ))}
    </div>
  )
}

export default CodeableConcept
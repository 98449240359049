import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import Narrative from '../../../DataTypes/Narrative'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Identifier from '../../../DataTypes/Identifier'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'
import AdverseReaction from '../../../DisplayComponents/AdverseReaction'

interface Props {
    allergyDetailId: any;
    allergyData: any;
    isFetching: boolean;
}
const config: FieldConfig = {
    patient: { label: 'Patient', visibility: 'always' },
    clinicalStatus: { label: 'Clinical Status', visibility: 'always' },
    verificationStatus: { label: 'Verification Status', visibility: 'always' },
    type: { label: 'Type', visibility: 'conditional' },
    category: { label: 'Category', visibility: 'conditional' },
    criticality: { label: 'Criticality', visibility: 'conditional' },
    code: { label: 'Code', visibility: 'always' },
    reaction: { label: 'Adverse Reaction', visibility: 'always' },
    text: { label: 'Summary', visibility: 'conditional' },

    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const AllergyDetail: FC<Props> = ({ allergyDetailId, allergyData, isFetching }: Props) => {
    const [resource, setResource] = useState<any>(null)
    const history = useHistory()

    useEffect(() => {
        if (!allergyData) return

        const foundAllergy = allergyData.entry.find((a: any) => a.resource.id === allergyDetailId)
        if (foundAllergy) {
            setResource(foundAllergy.resource)
        } else {
            history.push('/404')
        }
    }, [allergyData, allergyDetailId, history])

    return (
        <>
            {isFetching && (
                <div className='d-flex dataContainer'>
                    <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                    />
                    <span style={{ marginLeft: '10px' }}>Loading Member Allergies...</span>
                </div>
            )}
            <dl className='dataContainer'>
                {resource ? (
                    <div>
                        <Row>
                            <ShowField field='patient' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.patient.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <PatientReference patient={resource.patient} />
                                </Col>
                            </ShowField>
                            <ShowField field='clinicalStatus' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.clinicalStatus.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><CodeableConcept data={resource?.clinicalStatus} dataExtension={resource?._clinicalStatus} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='verificationStatus' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.verificationStatus.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><CodeableConcept data={resource?.verificationStatus} dataExtension={resource?._verificationStatus} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='type' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.type.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><Code data={resource.type} dataExtension={resource._type} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='category' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.category.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><Code data={resource.category} dataExtension={resource._category} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='criticality' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.criticality.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><Code data={resource.criticality} dataExtension={resource._criticality} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='code' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.code.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><CodeableConcept data={resource?.code} dataExtension={resource?._code} /></dd>
                                </Col>
                            </ShowField>
                        </Row>

                        <Row>
                            <ShowField field='reaction' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.reaction.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <AdverseReaction reactions={resource?.reaction} />
                                </Col>
                            </ShowField>
                            <ShowField field='text' config={config} resource={resource}>
                                <Col sm={4}>
                                    <dt>{config.text.label}</dt>
                                </Col>
                                <Col sm={8}>
                                    <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                                </Col>
                            </ShowField>
                        </Row>
                        <div className='footer'>
                            <hr />
                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType' config={config} resource={resource}>
                                    <Col sm={4}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd>{<ResourceType resourceType={resource?.resourceType} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id' config={config} resource={resource}>
                                    <Col sm={4}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta' config={config} resource={resource}>
                                    <Col sm={4}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><MetaLastUpdated meta={resource.meta} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' config={config} resource={resource.meta}>
                                    <Col sm={4}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd>{<Profile profile={resource.meta?.profile} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language' config={config} resource={resource}>
                                    <Col sm={4}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                    </Col >
                                </ShowField >
                                <ShowField field='implicitRules' config={config} resource={resource}>
                                    <Col sm={4}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='identifier' config={config} resource={resource}>
                                    <Col sm={4}>
                                        <dt>{config.identifier.label}</dt>
                                    </Col>
                                    <Col sm={8}>
                                        <Identifier identifiers={resource.identifier} />
                                    </Col>
                                </ShowField>
                            </Row >
                        </div >
                        <Row>
                            <Col sm={12}>
                                <ProvenanceDetail resourceName='AllergyIntolerance' resourceId={allergyDetailId} />
                            </Col>
                        </Row>
                    </div >
                ) : (
                    <div className='text-center'>
                        No Allergy Details found for this member.
                    </div>
                )}
            </dl >
        </>
    )
}
export default AllergyDetail